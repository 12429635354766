import './alertify.scss';

import _ from 'lodash';
import alertify from 'alertifyjs';
import { App } from 'Shared/resources/assets/app/js/App';
import { fire } from 'Shared/resources/assets/app/js/helpers/eventHelpers';
import { ts } from 'Shared/resources/assets/app/js/helpers/i18nHelpers';

/**
 * Notification framework used to send notifications.
 */
const Notification = {
    /**
     * Notification types
     */
    INFO: 'info',
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'error',

    /**
     * Shows an alert dialog.
     *
     * @param {string}   message         The message to be shown
     * @param {function} successCallback The callback called when OK button is pressed
     */
    alert: function (message, successCallback = () => {}) {
        this.getInstance().alert(message, successCallback);
    },

    /**
     * Shows a prompt dialog.
     *
     * @param {string}   message         The message to be shown
     * @param {function} successCallback The callback called when OK button is pressed
     * @param {function} failCallback    The callback called when Cancel button is pressed
     * @param {string}   defaultValue    The input default value
     */
    prompt: function (message, successCallback = () => {}, failCallback = () => {}, defaultValue = '') {
        this.getInstance().prompt(message, defaultValue, successCallback, failCallback);
    },

    /**
     * Shows a confirmation dialog.
     *
     * @param {string}   message         The message to be shown
     * @param {function} successCallback The callback called when OK button is pressed
     * @param {function} failCallback    The callback called when Cancel button is pressed
     * @param { ok: string, cancel: string } labels The buttons labels object
     */
    confirm: function (message, successCallback = () => {}, failCallback = () => {}, labels = null) {
        this.getInstance()
            .confirm(message, successCallback, failCallback)
            .set('labels', {
                ok: labels?.ok ?? ts('OK'),
                cancel: labels?.cancel ?? ts('CANCEL'),
            });
    },

    /**
     * Shows a notification of the given type.
     *
     * @param {string}      message         The message to be shown
     * @param {string}      type            The notification type
     * @param {boolean}     autoClose       Enable/Disable auto closing
     * @param {function}    dismissCallback The callback called when Dismiss button is pressed
     * @param {object|null} event           Event to be fired
     */
    notify: function (message, type, autoClose = true, dismissCallback = () => {}, event = null) {
        this.getInstance().notify(message, type, autoClose ? 6 : 600, dismissCallback);

        if (_.isPlainObject(event) && event.hasOwnProperty('identifier')) {
            fire(event.identifier, event.data || {});
        }
    },

    /**
     * Shows an info notification.
     *
     * @param {string}      message         The message to be shown
     * @param {boolean}     autoClose       Enable/Disable auto closing
     * @param {function}    dismissCallback The callback called when Dismiss button is pressed
     * @param {object|null} event           Event to be fired
     */
    info: function (message, autoClose = true, dismissCallback = () => {}, event = null) {
        this.notify(message, this.INFO, autoClose, dismissCallback, event);
    },

    /**
     * Shows an success notification.
     *
     * @param {string}      message         The message to be shown
     * @param {boolean}     autoClose       Enable/Disable auto closing
     * @param {function}    dismissCallback The callback called when Dismiss button is pressed
     * @param {object|null} event           Event to be fired
     */
    success: function (message, autoClose = true, dismissCallback = () => {}, event = null) {
        this.notify(message, this.SUCCESS, autoClose, dismissCallback, event);
    },

    /**
     * Shows an warning notification.
     *
     * @param {string}      message         The message to be shown
     * @param {boolean}     autoClose       Enable/Disable auto closing
     * @param {function}    dismissCallback The callback called when Dismiss button is pressed
     * @param {object|null} event           Event to be fired
     */
    warning: function (message, autoClose = true, dismissCallback = () => {}, event = null) {
        this.notify(message, this.WARNING, autoClose, dismissCallback, event);
    },

    /**
     * Shows an error notification.
     *
     * @param {string}      message         The message to be shown
     * @param {boolean}     autoClose       Enable/Disable auto closing
     * @param {function}    dismissCallback The callback called when Dismiss button is pressed
     * @param {object|null} event           Event to be fired
     */
    error: function (message, autoClose = true, dismissCallback = () => {}, event = null) {
        this.notify(message, this.ERROR, autoClose, dismissCallback, event);
    },

    /**
     * Get the Alertify object
     *
     * @return object
     */
    getInstance: function () {
        const alertifyInstance = { ...alertify };
        alertifyInstance.defaults = {
            ...alertifyInstance.defaults,
            closable: false,
            maximizable: false,
            resizable: false,
        };

        return alertifyInstance;
    },
};

App.Notification = Notification;

export { Notification };
