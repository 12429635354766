import styles from './LightTooltip.module.scss';

import PropTypes from 'prop-types';
import React from 'react';
import { ConfigProvider, Tooltip } from 'antd';

const LightTooltip = ({ overlayClassName, ...otherProps }) => {
    return (
        <ConfigProvider
            theme={{
                components: {
                    Tooltip: {
                        zIndexPopup: 2001,
                    },
                },
            }}
        >
            <Tooltip color="#edf4f6" overlayClassName={[styles.tooltip, overlayClassName].join(' ')} {...otherProps} />
        </ConfigProvider>
    );
};

LightTooltip.propTypes = {
    overlayClassName: PropTypes.string,
};

export { LightTooltip };
