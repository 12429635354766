import $ from 'jquery';
import { tp, ts } from 'Shared/resources/assets/app/js/helpers/i18nHelpers';
import { getTopWindowDocument } from 'Shared/resources/assets/app/js/helpers/windowHelpers';
import { onDocumentReady } from 'Shared/resources/assets/app/js/helpers/domHelpers';

const applyGlobals = () => {
    const topWindowDocument = getTopWindowDocument();

    topWindowDocument.ts = ts;
    topWindowDocument.tp = tp;
    topWindowDocument.onDocumentReady = onDocumentReady;
    topWindowDocument.$ = $;
    topWindowDocument.App = window.App;
};

applyGlobals();

export { applyGlobals };
