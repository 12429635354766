import $ from 'jquery';
import { tp, ts } from 'Shared/resources/assets/app/js/helpers/i18nHelpers';
import { onDocumentReady } from 'Shared/resources/assets/app/js/helpers/domHelpers';

const applyGlobals = () => {
    /* TODO:
     * the following is a workaround to have some objects/functions globally available
     * to the scripts that make use of them in the php blade files.
     * They should be refactored and the JS code should be placed in JS modules.
     * Once this is done, the following lines can be removed
     */
    window.ts = ts;
    window.tp = tp;
    window.onDocumentReady = onDocumentReady;
    window.document.$ = $; // This is required in order for iFrame communication to work
    window.$ = $;
};

applyGlobals();

export { applyGlobals };
