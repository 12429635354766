// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_@rspack+core@0.6.2_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/.pnpm/css-loader@6.11.0_@rspack+core@0.6.2_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tooltip--ugibY .ant-tooltip-inner{color:rgba(0,0,0,.88)}", "",{"version":3,"sources":["webpack://./app/Shared/resources/assets/app/js/ui/AntDesign/LightTooltip/LightTooltip.module.scss"],"names":[],"mappings":"AACI,mCACI,qBAAA","sourcesContent":[".tooltip {\n    :global(.ant-tooltip-inner) {\n        color: rgba(0, 0, 0, 0.88)\n\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": "tooltip--ugibY",
	"ant-tooltip-inner": "ant-tooltip-inner",
	"antTooltipInner": "ant-tooltip-inner"
};
export default ___CSS_LOADER_EXPORT___;
